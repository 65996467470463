import { Beat } from '../types';
const url: string = process.env.REACT_APP_API_URL || 'http://localhost:3001';

export async function getBeats(): Promise<Beat[] | undefined> {
  try {
    const result = await fetch(url + '/beats');
    const data: Beat[] = await result.json();
    return data;
  } catch (err) {
    console.error(err);
  }
}

export async function postBeat(beat: Beat): Promise<Beat | undefined> {
  try {
    const result = await fetch(url + '/beats', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(beat),
    });
    const data: Beat = await result.json();
    return data;
  } catch (err) {
    console.error(err);
  }
}
