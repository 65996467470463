import { motion, useAnimation } from 'framer-motion';
import { useState, useEffect } from 'react';
export interface IOrbitProps {
  speed: number;
  isPlaying: boolean;
  diameter?: number;
}

export default function Orbit(props: IOrbitProps) {
  const { speed, isPlaying, diameter } = props;
  const [deg, setDeg] = useState(0);
  const controls = useAnimation();
  function styles() {
    return {
      orbit: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        aspectRatio: 1,
        width: diameter ? diameter : '500px',
        borderRadius: '50%',
        border: '3px solid white',
      },
      planet: {
        aspectRatio: 1,
        width: '50px',
        borderRadius: '50%',
        backgroundColor: 'white',
        transform: `translateY(${
          diameter ? '-' + diameter / 2 + 'px' : '-250px'
        })`,
      },
    };
  }

  useEffect(() => {
    if (isPlaying) {
      controls.start({ rotate: deg });
      setTimeout(() => {
        setDeg(deg + speed);
      }, 500);
    } else {
      controls.stop();
    }
  }, [deg, isPlaying, speed, controls]);
  return (
    <motion.div
      animate={controls}
      transition={{
        ease: 'linear',
        duration: 2,
      }}
      style={{ ...styles().orbit, position: 'absolute' }}>
      <motion.div style={styles().planet}></motion.div>
    </motion.div>
  );
}
