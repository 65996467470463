import { Beat } from '../../types';
import { useHiveContext } from '../../contexts/HiveProvider';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
type Props = {
  beat: Beat;
  index: number;
  setThisCluster: React.Dispatch<React.SetStateAction<boolean>>;
  thisCluster: boolean;
};

export default function BeatHiveItem(props: Props) {
  const { beat, thisCluster, setThisCluster } = props;

  const [thisHover, setThisHover] = useState(false);
  const { isHover, setHover } = useHiveContext();
  const [scale, setScale] = useState(1);
  const [margin, setMargin] = useState(0);

  useEffect(() => {
    if (thisCluster && isHover && !thisHover) {
      setScale(0.9);
      setMargin(-10);
    } else setScale(1);
  }, [isHover, thisHover, thisCluster]);
  return (
    <motion.div
      className={`hive-item`}
      onMouseEnter={() => {
        setHover(true);
        setThisHover(true);
        setScale(1.2);
        setThisCluster(true);
      }}
      onMouseLeave={() => {
        setHover(false);
        setThisHover(false);
        setThisCluster(false);
      }}
      animate={{
        transform: `scale(${scale})`,
        margin: margin,
      }}>
      {<h5>{beat.name}</h5>}
    </motion.div>
  );
}
