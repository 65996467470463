import React, { createContext, useState, useMemo, useContext } from 'react';
import { Beat } from '../types';
import { getBeats, postBeat } from '../services/ApiService';
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutateFunction,
} from 'react-query';

interface IBeatProviderProps {
  children: JSX.Element | JSX.Element[];
}

interface IBeatContext {
  setBeats: React.Dispatch<(prev: Beat[]) => Beat[]>;
  beats: Beat[];
  menuItems: string[];
  saveBeat: UseMutateFunction<Beat | undefined, unknown, Beat, unknown>;
  setSelectedBeat: React.Dispatch<(prev: Beat[]) => Beat[]>;
  selectedBeat: Beat[];
}
const BeatContext = createContext<IBeatContext>({
  setBeats: () => {},
  beats: [],
  menuItems: [],
  saveBeat: () => {},
  setSelectedBeat: () => {},
  selectedBeat: [],
});

export default function BeatProvider(props: IBeatProviderProps) {
  const { children } = props;
  const [beats, setBeats] = useState([] as Beat[]);
  const [selectedBeat, setSelectedBeat] = useState([] as Beat[]);
  // let beats: Beat[] = [];
  const menuItems = ['Create', 'Library', 'Settings', 'Feed'];

  /* ----- React Query ----- */
  /* ----- Query / setState pattern with useMemo ----- */
  const { data } = useQuery('beats', getBeats);
  useMemo(() => {
    data && setBeats(data);
  }, [data]);

  /* ----- Mutation / Post Beats ----- */
  const queryClient = useQueryClient();
  const saveBeat = useMutation(postBeat, {
    onSuccess: () => {
      queryClient.invalidateQueries('beats');
    },
  }).mutate;

  return (
    <BeatContext.Provider
      value={{
        setBeats,
        beats,
        menuItems,
        saveBeat,
        setSelectedBeat,
        selectedBeat,
      }}>
      {children}
    </BeatContext.Provider>
  );
}
export const useBeatContext = () => useContext(BeatContext);
