import { useNavigate } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeProvider';

export interface IMenuButtonProps {
  item: string;
  isModal?: boolean;
}

export default function MenuItem(props: IMenuButtonProps) {
  const { isDarkTheme } = useTheme();
  const { item, isModal } = props;
  const navigate = useNavigate();
  function goTo() {
    const path = item.toLowerCase();
    navigate(path);
  }
  return (
    <div
      onClick={goTo}
      className={`btn-container ${isModal ? 'modal ' : ''} ${
        isDarkTheme ? 'dark' : ''
      }`}>
      {<p>{item}</p>}
    </div>
  );
}
