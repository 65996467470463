import { createContext, useState, useContext } from 'react';

interface IHiveProviderProps {
  children: JSX.Element | JSX.Element[];
}

interface IHiveContext {
  isHover: boolean;
  thisHover: boolean;
  notHover: boolean;
  setHover: React.Dispatch<React.SetStateAction<boolean>>;
  setThisHover: React.Dispatch<React.SetStateAction<boolean>>;
  setNotHover: React.Dispatch<React.SetStateAction<boolean>>;
}

const HiveContext = createContext<IHiveContext>({
  isHover: false,
  thisHover: false,
  notHover: false,
  setHover: () => {},
  setThisHover: () => {},
  setNotHover: () => {},
});

export default function HiveProvider(props: IHiveProviderProps) {
  const { children } = props;
  /* ----- If mouse hovers over a beat ----- */
  const [isHover, setHover] = useState(false);
  /* ----- If this beat is being hovered over ----- */
  const [thisHover, setThisHover] = useState(false);
  /* ----- If this beat is not being hovered over while other is ----- */
  const [notHover, setNotHover] = useState(false);

  return (
    <HiveContext.Provider
      value={{
        isHover,
        setHover,
        thisHover,
        setThisHover,
        notHover,
        setNotHover,
      }}>
      {children}
    </HiveContext.Provider>
  );
}
export const useHiveContext = () => useContext(HiveContext);
