import { useState } from 'react';
import { useTheme } from '../contexts/ThemeProvider';
import { motion } from 'framer-motion';
import MenuModal from './MenuModal';
export default function MenuIcon() {
  const { isDarkTheme } = useTheme();
  const [modalScale, setModalScale] = useState(-200);
  const [deg, setDeg] = useState(0);
  const [top, setTop] = useState(10);
  const [bottom, setBottom] = useState(10);
  const [opacity, setOpacity] = useState(1);
  const modalProps = { modalScale, setModalScale };
  function toggleModal() {
    if (modalScale < 0) {
      setDeg(135);
      setModalScale(0);
      setOpacity(0);
      setTop(40);
      setBottom(-20);
    } else {
      setDeg(0);
      setModalScale(-200);
      setOpacity(1);
      setTop(10);
      setBottom(10);
    }
  }
  return (
    <>
      <button className='menu-icon-container' onClick={toggleModal}>
        <div className='menu-icon'>
          <motion.div
            initial={{
              marginTop: `${top}px`,
            }}
            animate={{
              marginTop: `${top}px`,
              transform: `rotate(${deg}deg)`,
            }}
            className={`line ${isDarkTheme ? 'dark' : ''}`}></motion.div>
          <motion.div
            initial={{
              marginTop: `${top}px`,
              opacity: 1,
            }}
            animate={{
              opacity: opacity,
            }}
            className={`line ${isDarkTheme ? 'dark' : ''}`}></motion.div>
          <motion.div
            initial={{
              marginTop: `${bottom}px`,
            }}
            animate={{
              marginTop: `${bottom}px`,
              transform: `rotate(-${deg}deg)`,
            }}
            className={`line ${isDarkTheme ? 'dark' : ''}`}></motion.div>
        </div>
      </button>
      <MenuModal {...modalProps} />
    </>
  );
}
