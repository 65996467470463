import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useTheme } from './ThemeProvider';
import NavBar from '../pages/NavBar';

import Create from '../pages/Create';
import Library from '../pages/Library';
import Settings from '../pages/Settings';
import Feed from '../pages/Feed';

export interface INavigationProviderProps {
  children: JSX.Element | JSX.Element[];
}

type ComponentDict = {
  [key: string]: JSX.Element;
};

export default function NavigationProvider(props: INavigationProviderProps) {
  const { isDarkTheme } = useTheme();
  const { children } = props;
  const components: ComponentDict = {
    create: <Create />,
    library: <Library />,
    settings: <Settings />,
    feed: <Feed />,
  };

  return (
    <div className={`main-container ${isDarkTheme ? 'dark' : ''}`}>
      <Router>
        <NavBar />
        {children}
        <Routes>
          {Object.keys(components).map((key, index) => (
            <Route
              key={`${key}${index}`}
              path={key}
              element={components[key]}
            />
          ))}
        </Routes>
      </Router>
    </div>
  );
}
