import { useTheme } from '../contexts/ThemeProvider';
export interface IThemeButtonProps {
  isModal?: boolean;
}
export default function ThemeButton(props: IThemeButtonProps) {
  const { isModal } = props;
  const { toggleTheme, isDarkTheme } = useTheme();
  function styles(): { [key: string]: React.CSSProperties } {
    return {
      theme: {
        height: '15px',
        width: '50px',
        backgroundColor: 'white',
        border: '1px solid black',
        borderRadius: '10px',
      },
      radio: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        aspectRatio: 1,
        width: '25px',
        backgroundColor: isDarkTheme ? 'white' : 'black',
        borderRadius: '50%',
        border: '1px solid black',
        position: 'absolute',
        marginLeft: isDarkTheme ? '70px' : '10px',
      },
      icon: {
        color: !isDarkTheme ? 'white' : 'black',
        fontSize: '20px',
        cursor: 'pointer',
      },
    };
  }
  return (
    <>
      <div
        className={`theme-btn ${isModal ? 'modal' : ''}`}
        onClick={toggleTheme}>
        <h6 style={{ marginRight: 5 }}>Theme</h6>
        <div style={styles().theme}></div>
        <div style={styles().radio}>
          <h4 style={styles().icon}>{isDarkTheme ? '☽' : '☼'}</h4>
        </div>
      </div>
    </>
  );
}
