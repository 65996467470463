import './App.css';
import BeatProvider from './contexts/BeatProvider';
import NavigationProvider from './contexts/NavigationProvider';
import HiveProvider from './contexts/HiveProvider';
import { QueryClient, QueryClientProvider } from 'react-query';

/* ----- React Query ----- */
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BeatProvider>
        <HiveProvider>
          <NavigationProvider>{/* {} */}</NavigationProvider>
        </HiveProvider>
      </BeatProvider>
    </QueryClientProvider>
  );
}

export default App;
