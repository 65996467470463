import { Beat } from '../../types';
import { motion } from 'framer-motion';
import { useState } from 'react';
import BeatHiveItem from './BeatHiveItem';
export type Props = {
  list: Beat[];
  index: number;
};

export default function HiveCluster(props: Props) {
  const { list } = props;
  const [thisCluster, setThisCluster] = useState(false);

  return (
    <motion.div className='hive-cluster'>
      {list.map((beat, i) => {
        const span = i === 1 || i === 6 ? 'span-3' : 'span-2';
        const props = {
          thisCluster,
          setThisCluster,
          beat,
          index: i,
        };
        return (
          <div className={`hive-item-ctn ${span} `} key={`${beat.name}-${i}`}>
            <BeatHiveItem {...props} />
          </div>
        );
      })}
    </motion.div>
  );
}
