import { useState } from 'react';
import { useBeatContext } from '../contexts/BeatProvider';
import { Beat } from '../types';

export default function Create() {
  const [name, setName] = useState('');
  const { setBeats, saveBeat } = useBeatContext();

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const beat: Beat = { name };
    saveBeat(beat);
    setBeats((beats) => [...beats, beat]);
    setName('');
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setName(e.target.value);
  }

  return (
    <div className='create-container'>
      <h3>Create</h3>
      <form onSubmit={handleSubmit}>
        <input type='text' name='name' value={name} onChange={handleChange} />
        <button type='submit'>Create</button>
      </form>
    </div>
  );
}
