import { motion } from 'framer-motion';
import { useBeatContext } from '../../contexts/BeatProvider';
import { beatSplit } from '../../utils/helpers';
import HiveCluster from './HiveCluster';
export default function BeatHive() {
  const { beats } = useBeatContext();
  const map = beatSplit(beats);

  return (
    <motion.div className='hive'>
      {Object.keys(map).map((key, index) => (
        <HiveCluster key={`${key}-${index}`} list={map[key]} index={index} />
      ))}
    </motion.div>
  );
}
