import { useTheme } from '../contexts/ThemeProvider';
import { useBeatContext } from '../contexts/BeatProvider';
import MenuItem from './MenuItem';
import ThemeButton from './ThemeButton';
export interface IMenuModalProps {
  modalScale: number;
  setModalScale: React.Dispatch<React.SetStateAction<number>>;
}

export default function MenuModal(props: IMenuModalProps) {
  const { isDarkTheme } = useTheme();
  const { modalScale } = props;
  const { menuItems } = useBeatContext();

  return (
    <div className='modal-container'>
      <div
        className={`menu-modal ${isDarkTheme ? 'dark' : ''}`}
        style={{ right: `${modalScale}px` }}>
        {menuItems.map((item: string, index: number) => (
          <div key={`${item}${index}`} style={{ display: 'flex' }}>
            <MenuItem item={item} isModal={true} />
          </div>
        ))}
        <ThemeButton isModal={true} />
      </div>
    </div>
  );
}
