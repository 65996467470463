// import BeatList from '../components/BeatList';
// import BeatDetails from '../components/BeatDetails';
import BeatHive from '../components/Hive/BeatHive';
export interface ILibraryProps {}

export default function Library(props: ILibraryProps) {
  return (
    <div className='lib-container'>
      <BeatHive />
      {/* <BeatList />
      <BeatDetails /> */}
    </div>
  );
}
