import { Beat, Map } from '../types';

export function beatSplit(list: Beat[]) {
  const copy = list.slice();
  let count = 1;
  const map: Map = {};
  while (copy.length) {
    map[count] = copy.splice(0, 7);
    count++;
  }
  return map;
}
