import { createContext, useContext, useState } from 'react';

import useWindowDimensions from '../utils/useWindowDimensions';
export interface IThemeProviderProps {
  children: JSX.Element | JSX.Element[];
}

interface IThemeContext {
  isDarkTheme: boolean;
  toggleTheme: () => void;
  width: number | null;
}

export const ThemeContext = createContext<IThemeContext>({
  isDarkTheme: false,
  toggleTheme: () => {},
  width: null,
});

export default function ThemeProvider(props: IThemeProviderProps) {
  const { children } = props;
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const { width } = useWindowDimensions();
  function toggleTheme(): void {
    setIsDarkTheme(!isDarkTheme);
  }

  return (
    <ThemeContext.Provider value={{ isDarkTheme, toggleTheme, width }}>
      {children}
    </ThemeContext.Provider>
  );
}

export const useTheme = () => useContext(ThemeContext);
