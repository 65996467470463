import MenuButton from '../components/MenuItem';
import ThemeButton from '../components/ThemeButton';
import MenuIcon from '../components/MenuIcon';
import { useBeatContext } from '../contexts/BeatProvider';
import { useTheme } from '../contexts/ThemeProvider';
export default function NavBar() {
  const { menuItems } = useBeatContext();
  const { isDarkTheme, width } = useTheme();

  return (
    <div className='navbar'>
      <div className='logo-container'>
        <img
          alt='logo'
          className='logo'
          src={require(`../assets/${
            isDarkTheme ? 'logo.png' : 'logo-black.png'
          }`)}></img>
        <h1>beatlog</h1>
      </div>
      {width && width > 960 ? (
        <>
          <div className='navbar-buttons'>
            {menuItems.map((item, index) => (
              <MenuButton key={`${item}${index}`} item={item} />
            ))}
          </div>
          <ThemeButton />
        </>
      ) : (
        <MenuIcon />
      )}
    </div>
  );
}
