import { useState, useEffect } from 'react';

export default function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';
  const [width, setWidth] = useState(hasWindow ? window.innerWidth : null);
  const [height, setHeight] = useState(hasWindow ? window.innerHeight : null);

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      }
      window.addEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  return { width, height };
}
