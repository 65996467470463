import { useState } from 'react';
import Orbit from '../components/Orbit';
export default function Feed() {
  const [isPlaying, setIsPlaying] = useState(false);
  return (
    <div className='feed-container'>
      <Orbit speed={10} isPlaying={isPlaying} />
      <Orbit speed={20} isPlaying={isPlaying} />
      <Orbit speed={25} isPlaying={isPlaying} />
      <Orbit speed={80} isPlaying={isPlaying} diameter={300} />
      <Orbit speed={100} isPlaying={isPlaying} diameter={300} />
      <Orbit speed={70} isPlaying={isPlaying} diameter={300} />
      <button
        onClick={() => {
          console.log('CLICKED');
          setIsPlaying(!isPlaying);
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          position: 'absolute',
          top: '45vh',
          left: '49vw',
          aspectRatio: 1,
          width: 60,
          borderRadius: '50%',
          fontWeight: 'bold',
        }}>
        <p>PLAY</p>
      </button>
    </div>
  );
}
